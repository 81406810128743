export enum AbsenceOptionUnit {
  DAYS = 'days',
  HOURS = 'hours',
}

export enum CursorType {
  NO_DROP = 'no-drop',
  POINTER = 'pointer',
  GRABBING = 'grabbing',
}

export enum DecimalPrecision {
  DEFAULT = 2,
  PERCENTAGE = 2,
  VACATION_CALCULATION = 5,
  MONEY = 2,
}

export enum AccountType {
  SHIFTBASE = 'ShiftBase',
}

export enum CanCreateOptions {
  NOT_ALLOWED_PERMISSION = 'not-allowed-permission',
  ALLOWED = 'allowed',
  NOT_ALLOWED_INACTIVE = 'not-allowed-inactive',
}

export enum EnvironmentType {
  DEVELOPMENT = 'dev',
  STAGING = 'staging',
  REVIEW = 'review',
  PRODUCTION = 'production',
}

export enum AbsenceCalculationType {
  CONTRACT = 'contract',
  SCHEDULED = 'scheduled',
  NONE = 'none',
}

export enum PeriodType {
  TEAM,
  EMPLOYEE,
}

export enum ViewType {
  EMPLOYEE = 'employee',
  TEAM = 'team',
}

export enum ColumnFiltersType {
  NONE,
  PERIOD_OVERVIEW = 'period-overview-columns',
  SCHEDULE_OVERVIEW = 'schedule-overview-columns',
  SCHEDULE_DETAIL = 'schedule-detail-columns',
  OPEN_SHIFTS = 'open-shifts-columns',
  EMPLOYEES_PLUS_MINUS = 'employees-plus-minus-columns',
  EMPLOYEES_AVAILABILITY = 'employees-availability-columns',
  EMPLOYEES = 'employees-columns',
  EMPLOYEES_ABSENT = 'employees-absent-columns',
  TURNOVER = 'turnover-columns',
  PAYROLL = 'payroll-columns',
  TIMESHEET_OVERVIEW = 'timesheet-overview-columns',
  TIMESHEET_DIARY = 'timesheet-diary-columns',
  TIMESHEET_CLOSED = 'timesheet-closed-columns',
  TIMESHEET_DETAIL = 'timesheet-detail-columns',
  SCHEDULE_TIMESHEET = 'schedule-timesheet-columns',
}

export enum ReportExportType {
  HTML = 'html',
  EXCEL = 'xlsx',
  CSV = 'csv',
}

export enum ChangeDetectionType {
  DETECT_CHANGES,
  MARK_FOR_CHECK,
}

export enum DragType {
  OPEN_SHIFT,
  REQUIRED_SHIFT,
  SCHEDULE,
  SHIFT,
  EVENT,
}

export enum DropZoneType {
  OPEN_SHIFT,
  REQUIRED_SHIFT,
  SCHEDULE,
  SHIFT,
  EVENT,
}

export enum Features {
  // Permanent featureflag, in case Widgetbrain has trouble on their side again.
  WIDGETBRAIN = 'widgetbrain',
  SHOW_TODAY_COUNT = 'show_today_count',
  SHOW_CHARGEBEE_TEST_BUTTONS = 'show_chargebee_test_buttons',
  SENTRY_LOGGING = 'sentry_logging',
  PHRASE_IN_CONTEXT_EDITOR = 'phrase-in-context-editor',
  IMPORT_PUBLIC_HOLIDAYS = 'import_public_holidays',

  // Temporary featureflags, to be removed when the feature is fully rolled out.
  TMP_CHARGEBEE_MIGRATION_RUNNING = 'tmp_chargebee_migration_running',
  TMP_SHIFT_TEMPLATE_EMPLOYABILITY_ENDPOINT = 'tmp_shift_template_employability_endpoint',
  TMP_HIGH_IMPACT_WARNING = 'tmp_high_impact_warning',
  TMP_NEW_SIGNUP = 'tmp_new_signup',
  TMP_QUICK_SCHEDULES = 'tmp_quick_schedules',
  TMP_QUICK_SCHEDULES_INTRO_BANNER = 'tmp_quick_schedules_intro_banner',
  TMP_PERSONIO_INTEGRATION_ABSENCE = 'tmp_personio_integration_absence',
  TMP_STARTER_TO_BASIC = 'tmp_starter_to_basic',
  TMP_REFINER = 'tmp_refiner',
  TMP_TIMESHEET_STATEMENTS = 'tmp_timesheet_statements',
  TMP_TIMESHEET_STATEMENTS_INTRO_BANNER = 'tmp_timesheet_statements_intro_banner',
  TMP_REQUEST_GUESTPLAN_INTEGRATION = 'tmp_request_guestplan_integration',
  TMP_SCHEDULED_DOWNGRADES = 'tmp_scheduled_downgrades',
  TMP_HOORAYHR_TEAM_MAPPING = 'tmp_hoorayhr_team_mapping',
  TMP_AFAS_LEAVE_OF_ABSENCE = 'tmp_afas_leave_of_absence',
  TMP_APPROVE_TIMESHEETS_SHOW_EXTERNAL_EMPLOYEES = 'tmp_approve_timesheets_show_external_employees',
  TMP_NEW_ABSENCE_APPROVAL = 'tmp_new_absence_approval',
  TMP_OPEN_ENDED_ABSENCES = 'tmp_open_ended_absences',
  TMP_CONTRACT_TYPE_COC = 'tmp_contract_type_coc',
  TMP_AFAS_REST_PAYROLL = 'tmp_afas_rest_payroll',
  TMP_NEW_REPORTS_SIDEPANEL = 'tmp_new_reports_sidepanel',
  TMP_PERSONIO_ORGANISATION_WIDE_MAPPING = 'tmp_personio_organisation_wide_mapping',
  TMP_AFAF_SEND_WELCOME_EMAIL_ON_EMPLOYEE_IMPORT = 'tmp_afas_send_welcome_email_on_employee_import',
  TMP_CONTRACT_TYPE_COC_ANNOUNCEMENT = 'tmp_contract_type_coc_announcement',
  TMP_NEW_ADD_ABSENCE_MODAL = 'tmp_new_add_absence_modal',

  TMP_BUYER_INTENT_URGENCY = 'tmp_buyer_intent_urgency',

  TMP_AUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS = 'tmp_automated_absence_creation_public_holidays',
  TMP_AUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS_ANNOUNCEMENT = 'tmp_automated_absence_creation_public_holidays_announcement',
  TMP_PROSPERSTACK = 'tmp_prosperstack',
}

export enum DateFormatType {
  DEFAULT = 'yyyy-MM-dd',
  DAY_FULL = 'eeeeee P',
  DATE_WITH_TIME = 'yyyy-MM-dd HH:mm:ss',
  TIME = 'HH:mm',
  LONG_LOCALIZED = 'P',
}

export enum DropZoneType {
  COPY = 'copy-dropzone',
  MOVE = 'move-dropzone',
  NONE = 'none-dropzone',
}

export const IntlFormatType = {
  DATETIME_WITH_SECONDS: { dateStyle: 'short', timeStyle: 'medium' },
  DATETIME: { dateStyle: 'short', timeStyle: 'short' },
  DATE: { dateStyle: 'short' },
};
